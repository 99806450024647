.App, .Faq {
  text-align: center;
  background-color: #282c34;
  height : 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header, .Faq-header {
  font-size: calc(10px + 2vmin);
  color: white;
}

.Faq-answer {
  font-size: 16px;
  color: gray;

}
.App-link {
  color: #09d3ac;
}
